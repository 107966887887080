$primary:       #5802D9 !default;
$secondary:     #495057 !default;
$success:       #0DD929 !default;
$info:          #439CDB !default;
$warning:       #D98B18 !default;
$danger:        #D90D42 !default;

@import "~bootstrap/scss/bootstrap";

/* Doctor Chat */
.rcw-client .rcw-message-text {
  background-color: $success;
}

.quick-button {
  border: 2px solid $primary;
}

.quick-button:active {
  background: $primary;
}

.rcw-conversation-container .rcw-header {
  background-color: $primary;
}

.rcw-full-screen .rcw-close-button {
  background-color: $primary;
}

@media screen and (max-width: 800px) {
  .rcw-conversation-container .rcw-close-button {
    background-color: $primary;
  }
}

.rcw-launcher {
  background-color: $primary;
}
