.messenger {
    min-height: 80vh;
}

.messenger-search {
    border-radius: 0;
    border-bottom-left-radius: 0.3rem;
    font-size: 0.9rem;
    padding: 1.5rem 0.75rem;
}

.messenger-choose-dialog {
    line-height: 80vh;
}

.messenger-dialog {
    cursor: pointer;
}

.messenger-dialog.active {
    cursor: default;
}

.text-small {
    font-size: 0.9rem;
}

.messages-box,
.chat-box {
    height: 80vh;
    overflow-y: scroll;
}

.rounded-lg {
    border-radius: 0.5rem;
}

input::placeholder {
    font-size: 0.9rem;
    color: #999;
}
