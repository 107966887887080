.th-sort {
    cursor: pointer;
}

.th-sort span {
    display: inline-block;
    width: 0;
    height: 0;
    vertical-align: middle;
    border-top: 4px dashed;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    margin: 10px 6.5px;
}

.th-sort span.up {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed;
}
